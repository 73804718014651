<template>
  <div id="download">
    <div class="baner">
      <!-- 安卓 -->
      <div class="baner-Android shous">
        <img class="baner-Android-imgbox" src="@/assets/Android.png" />
      </div>
      <!-- ios -->
      <div class="baner-IOS shous">
        <img class="baner-IOS-imgbox" src="@/assets/IOS.png" />
      </div>
    </div>
    <div class="top">
      <div class="top-poa"></div>
      <div class="top-box flex">
        <div class="top-box-L">
          <div class="hui9992 fs40 fwb">PRODUCT</div>
          <div class="hui9992 fs40 fwb">SERVICE</div>
          <div class="top-box-L-tiao flex">
            <div class="top-box-L-tiao-L"></div>
            <div class="top-box-L-tiao-R"></div>
          </div>
          <div class="fs36 fw6" style="margin: 28px 0 130px">产品服务</div>
          <div>
            <div
              v-for="(item, index) in tabbar"
              :key="index"
              class="top-box-L-b"
            >
              <div
                class="fs18 shous"
                :class="item.id == tabbarId ? 'fw5 redf89' : 'hui555'"
                @click="cliSW(item)"
              >
                {{ item.name }}
              </div>
              <div class="top-box-L-tiao2" v-if="item.id == tabbarId"></div>
            </div>
          </div>
        </div>
        <div class="top-box-R flex JCspaceBetween FWw">
          <div v-for="(item, index) in list" :key="index" class="top-box-R-box">
            <img class="top-box-R-box-icon" :src="item.img" />
            <div
              class="fs20 hui333 fw6"
              style="margin-top: 30px; margin-left: 50px"
            >
              {{ item.name }}
            </div>
            <div class="top-box-R-box-text hui999 fs14">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="box-li flex JCspaceBetween ALcenter">
        <div class="box-li-bg1"></div>
        <div class="box-li-box">
          <div class="box-li-box-bg1"></div>
          <div class="fs36 fw6">智慧门禁</div>
          <div class="fs24 hui999" style="margin: 10px 0 36px">
            Smart Access Control
          </div>
          <div class="fs16 hui333 box-li-box-text">
            通过打通道闸门禁系统，实现“手机在手，畅行无忧”的出入体验。可用手机录入人脸，实现无感刷脸开门；可以使用通行二维码，实现扫码开门；也可以使用NFC，实现非触碰式感应开门。
          </div>
        </div>
      </div>

      <div class="box-li flex JCspaceBetween ALcenter">
        <div class="box-li-box">
          <div class="box-li-box-bg2"></div>
          <div class="fs36 fw6">访客通行</div>
          <div class="fs24 hui999" style="margin: 10px 0 36px">
            Visitor Access
          </div>
          <div class="fs16 hui333 box-li-box-text">
            通过向访客发放时效性通行二维码，实现“无需接送，轻松会客”的接待体验。可设置准许通行的小区道闸和单元门编号，可设置到访时间和有效时间，让业主无需通过物业即可快捷线上办理，免去不必要的接送烦恼。
          </div>
        </div>
        <div class="box-li-bg2"></div>
      </div>

      <div class="box-li flex JCspaceBetween ALcenter">
        <div class="box-li-bg3"></div>
        <div class="box-li-box">
          <div class="box-li-box-bg3"></div>
          <div class="fs36 fw6">家政保洁</div>
          <div class="fs24 hui999" style="margin: 10px 0 36px">
            House Cleaning
          </div>
          <div class="fs16 hui333 box-li-box-text">
            保姆月嫂、家庭保洁、家电维修、管道疏通通过生活服务功能实现日常烦恼全无忧，对比传统物业模式漫长的等待，小达实现一键呼叫，即刻上门。
          </div>
        </div>
      </div>

      <div class="box-li flex JCspaceBetween ALcenter">
        <div class="box-li-box">
          <div class="box-li-box-bg4"></div>
          <div class="fs36 fw6">社区社交</div>
          <div class="fs24 hui999" style="margin: 10px 0 36px">
            Social Networking in the Community
          </div>
          <div class="fs16 hui333 box-li-box-text">
            实现社区轻社交，“动态发布、社区活动、二手交易、租房车位”让业主随时随地了解小区新消息。
          </div>
        </div>
        <div class="box-li-bg4"></div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
export default {
  name: "Download",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      tabbarId: 1,
      tabbar: [
        {
          id: 1,
          name: "智慧管家",
        },
        {
          id: 2,
          name: "生活服务",
        },
        {
          id: 3,
          name: "邻里社交",
        },
      ],
      list: [
        {
          img: require("@/assets/dow1.png"),
          name: "智慧门禁",
          text: "刷脸扫码NFC，一部手机搞定所有门禁",
        },
        {
          img: require("@/assets/dow2.png"),
          name: "访客通行",
          text: "发送访客码，无需接送，轻松会客",
        },
        {
          img: require("@/assets/dow3.png"),
          name: "生活缴费",
          text: "水电物业费，线上缴纳，无需出门",
        },
        {
          img: require("@/assets/dow4.png"),
          name: "公共报事",
          text: "垃圾清运、设施维修、花园维护、线上报送，及时响应",
        },
      ],
    };
  },
  mounted() {
    // console.log(1);
  },
  methods: {
    cliSW(e) {
      this.tabbarId = e.id;
      if (e.id == 1) {
        let dd = [
          {
            img: require("@/assets/dow1.png"),
            name: "智慧门禁",
            text: "刷脸扫码NFC，一部手机搞定所有门禁",
          },
          {
            img: require("@/assets/dow2.png"),
            name: "访客通行",
            text: "发送访客码，无需接送，轻松会客",
          },
          {
            img: require("@/assets/dow3.png"),
            name: "生活缴费",
            text: "水电物业费，线上缴纳，无需出门",
          },
          {
            img: require("@/assets/dow4.png"),
            name: "公共报事",
            text: "垃圾清运、设施维修、花园维护、线上报送，及时响应",
          },
        ];
        this.list = dd;
      } else if (e.id == 2) {
        let dd = [
          {
            img: require("@/assets/dow5.png"),
            name: "保姆月嫂",
            text: "饮食起居，育婴陪护，随叫随到的生活管家",
          },
          {
            img: require("@/assets/dow6.png"),
            name: "家庭保洁",
            text: "日常打扫，新房开荒，这里有您想要的品质生活",
          },
          {
            img: require("@/assets/dow7.png"),
            name: "家电维修",
            text: "电清洗，家电维修，专业服务，免费上门",
          },
          {
            img: require("@/assets/dow8.png"),
            name: "管道疏通",
            text: "马桶、水槽、下水道，疏通堵漏，线上预约",
          },
        ];
        this.list = dd;
      } else if (e.id == 3) {
        let dd = [
          {
            img: require("@/assets/dow9.png"),
            name: "社区活动",
            text: "志愿服务，活动组织，线上报名，高效便捷",
          },
          {
            img: require("@/assets/dow10.png"),
            name: "二手交易",
            text: "闲置转让，二手购买，内部圈子，安全可靠",
          },
          {
            img: require("@/assets/dow11.png"),
            name: "租房车位",
            text: "租房子租车位，业主直租，无中介无套路",
          },
          {
            img: require("@/assets/dow12.png"),
            name: "动态分享",
            text: "生活分享，社区公告，有效社交，和谐邻里",
          },
        ];
        this.list = dd;
      }
    },
  },
};
</script>

<style scoped lang="less">
#download {
  .baner {
    width: 1920px;
    height: 780px;
    background-image: url(~@/assets/download1.png);
    background-size: 100% 100%;
    position: relative;
    .baner-Android {
      position: absolute;
      width: 201px;
      height: 48px;
      top: 516px;
      left: 1217px;
      .baner-Android-imgbox {
        width: 166px;
        height: 166px;
        position: absolute;
        right: -100%;
        top: -150%;
        display: none;
      }
    }
    .baner-Android:hover .baner-Android-imgbox {
      display: block;
    }
    .baner-IOS {
      position: absolute;
      width: 201px;
      height: 48px;
      top: 596px;
      left: 1217px;
      .baner-IOS-imgbox {
        width: 166px;
        height: 166px;
        position: absolute;
        right: -100%;
        top: -150%;
        display: none;
      }
    }
    .baner-IOS:hover .baner-IOS-imgbox {
      display: block;
    }
  }
  .top {
    width: 1920px;
    height: 506px;
    position: relative;
    .top-poa {
      width: 876px;
      height: 674px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: url(~@/assets/download2.png);
      background-size: 100% 100%;
      z-index: -1;
    }
    .top-box {
      width: 1200px;
      height: 506px;
      margin: 100px 360px;
      .top-box-L {
        width: 364px;
        height: 706px;
        .top-box-L-tiao {
          width: 364px;
          margin-top: 28px;
          height: 6px;
          .top-box-L-tiao-L {
            width: 64px;
            height: 6px;
            background: #f8951d;
            margin-right: 8px;
          }
          .top-box-L-tiao-R {
            width: 12px;
            height: 6px;
            background: #f8951d;
          }
        }
        .top-box-L-tiao2 {
          width: 70px;
          height: 3px;
          margin-top: 2px;
          background: rgba(248, 149, 29, 0.5);
          border-radius: 4px 4px 4px 4px;
        }
        .top-box-L-b {
          height: 60px;
        }
      }
      .top-box-R {
        width: 836px;
        .top-box-R-box {
          width: 375px;
          height: 225px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 2px rgba(18, 17, 39, 0.05);
          border-radius: 10px 10px 10px 10px;
          .top-box-R-box-icon {
            height: 32px;
            margin-left: 50px;
            margin-top: 50px;
          }
          .top-box-R-box-text {
            width: 292px;
            margin-left: 50px;
            margin-top: 12px;
            line-height: 26px;
          }
        }
      }
    }
  }
  .box {
    width: 1200px;
    margin: 0 360px;
    // height: 600px;
    .box-li {
      width: 1200px;
      height: 600px;
      .box-li-bg1 {
        width: 593px;
        height: 400px;
        background-image: url(~@/assets/download3.png);
        background-size: 100% 100%;
      }
      .box-li-bg2 {
        width: 426px;
        height: 426px;
        background-image: url(~@/assets/download4.png);
        background-size: 100% 100%;
      }
      .box-li-bg3 {
        width: 553px;
        height: 415px;
        background-image: url(~@/assets/download5.png);
        background-size: 100% 100%;
      }
      .box-li-bg4 {
        width: 472px;
        height: 472px;
        background-image: url(~@/assets/download6.png);
        background-size: 100% 100%;
      }
      .box-li-box {
        width: 440px;
        position: relative;
        .box-li-box-text {
          line-height: 24px;
        }
        .box-li-box-bg1 {
          position: absolute;
          right: 0;
          bottom: -40%;
          width: 241px;
          height: 146px;
          background-image: url(~@/assets/resident-01.png);
          background-size: 100% 100%;
          z-index: -1;
        }
        .box-li-box-bg2 {
          position: absolute;
          left: 0;
          bottom: -40%;
          width: 241px;
          height: 146px;
          background-image: url(~@/assets/resident-02.png);
          background-size: 100% 100%;
          z-index: -1;
        }
        .box-li-box-bg3 {
          position: absolute;
          right: 0;
          bottom: -40%;
          width: 241px;
          height: 146px;
          background-image: url(~@/assets/resident-03.png);
          background-size: 100% 100%;
          z-index: -1;
        }
        .box-li-box-bg4 {
          position: absolute;
          left: 0;
          bottom: -40%;
          width: 241px;
          height: 146px;
          background-image: url(~@/assets/resident-04.png);
          background-size: 100% 100%;
          z-index: -1;
        }
      }
    }
  }
}
</style>